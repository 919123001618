import Expense from '../models/Expense';
import BaseCollection from '@/app/base/collections/BaseCollection';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class ExpenseCollection extends BaseCollection<Expense> {
    model: ModelType = ModelType.EXPENSES;

    byStatus(status: string): Expense[] {
        return this.filter((Expense: Expense) => Expense.hasStatus(status));
    }
}
