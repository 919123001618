import LoginQrCode from '../auth/models/LoginQrCode';
import Tenant from '../auth/models/Tenant';
import User from '../auth/models/User';
import { BaseSchema, ModelType } from '../base/schemas/BaseSchema';
import Contact from '../contact/models/Contact';
import Lead from '../crm/models/Lead';
import Product from '../inventory/models/Product';
import ProductCategory from '../inventory/models/ProductCategory';
import ProductSection from '../inventory/models/ProductSection';
import ProductUnit from '../inventory/models/ProductUnit';
import Expense from '../invoicing/models/Expense';
import Invoice from '../invoicing/models/Invoice';
import ModelEvent from '../common/models/ModelEvent';
import InvoiceLine from '../invoicing/models/InvoiceLine';
import InvoicePayment from '../invoicing/models/InvoicePayment';
import InvoiceSection from '../invoicing/models/InvoiceSection';
import InvoicingNumberingSetting from '../invoicing/models/InvoicingNumberingSetting';
import InvoicingDocumentsSetting from '../invoicing/models/InvoicingDocumentsSetting';
import VatRate from '../invoicing/models/VatRate';
import Pdf from '../pdf/models/Pdf';
import PdfImage from '../pdf/models/PdfImage';
import CalendarEvent from '../planning/models/CalendarEvent';
import TimeActivity from '../planning/models/TimeActivity';
import TimeActivityGeolocation from '../planning/models/TimeActivityGeolocation';
import TimeActivityPause from '../planning/models/TimeActivityPause';
import Task from '../task/models/Task';
import Worksite from '../worksite/models/Worksite';
import ModelFile from '../common/models/ModelFile';
import ModelFileFolder from '../common/models/ModelFileFolder';
import WorksitePhase from '../worksite/models/WorksitePhase';
import WorksiteProfitabilityDocument from '../worksite/models/WorksiteProfitabilityDocument';
import InvoicingPaymentRemindersSetting from '../invoicing/models/InvoicingPaymentRemindersSetting';
import InvoicingNotificationsSetting from '../invoicing/models/InvoicingNotificationsSetting';
import NotificationLog from '../common/models/NotificationLog';
import Workfield from '../common/models/Workfield';
import WorksiteProgressBilling from '../worksite/models/WorksiteProgressBilling';
import WorksiteProgressBillingLine from '../worksite/models/WorksiteProgressBillingLine';
import WorksiteClosureReport from '../worksite/models/WorksiteClosureReport';
import InvoiceAutosave from '../invoicing/models/InvoiceAutosave';
import Source from '../crm/models/Source';
import ImportedFile from '../common/models/ImportedFile';
import LoginMagicLink from '../auth/models/LoginMagicLink';
import TimeActivitySetting from '../planning/models/TimeActivitySetting';
import Connection from '../common/models/Connection';
import WasteData from '../invoicing/models/WasteData';
import BankAccount from '~/app/bankaccount/models/BankAccount';
import BankAccountTransaction from '~/app/bankaccount/models/BankAccountTransaction';
import BankAccountAuthorization from '~/app/bankaccount/models/BankAccountAuthorization';
import InvoiceTemplate from '~/app/invoicing/models/InvoiceTemplate';
import InvoiceTemplateSection from '~/app/invoicing/models/InvoiceTemplateSection';
import InvoiceTemplateSectionColumn from '~/app/invoicing/models/InvoiceTemplateSectionColumn';
import InvoiceAnnexPdf from '~/app/invoicing/models/InvoiceAnnexPdf';
import PlanningItem from '~/app/planning/models/PlanningItem';
import GlobalSearchItem from '@/app/common/models/GlobalSearchItem';
import Setting from '@/app/common/models/Setting';
import ProductImage from '@/app/inventory/models/ProductImage';
import Notification from '@/app/common/models/Notification';
import InvoiceDiscount from '@/app/invoicing/models/InvoiceDiscount';
import WasteCenter from '@/app/common/models/WasteCenter';
import TermsOfService from '@/app/common/models/TermsOfService';
import TermsOfServiceAcceptation from '@/app/common/models/TermsOfServiceAcceptation';
import ComposedWorkLine from '@/app/inventory/models/ComposedWorkLine';

export default class ModelFactory {
    static getClass(type: ModelType): any {
        switch (type) {
            case ModelType.TENANT_USERS:
                return User;
            case ModelType.USERS:
                return User;
            case ModelType.TENANTS:
                return Tenant;
            case ModelType.LOGIN_QR_CODES:
                return LoginQrCode;
            case ModelType.LOGIN_MAGIC_LINK:
                return LoginMagicLink;

            case ModelType.CONTACTS:
                return Contact;

            case ModelType.LEADS:
                return Lead;
            case ModelType.SOURCES:
                return Source;

            case ModelType.TASKS:
                return Task;

            case ModelType.PRODUCTS:
                return Product;
            case ModelType.PRODUCT_UNITS:
                return ProductUnit;
            case ModelType.PRODUCT_CATEGORIES:
                return ProductCategory;
            case ModelType.PRODUCT_SECTIONS:
                return ProductSection;
            case ModelType.COMPOSED_WORK_LINES:
                return ComposedWorkLine;

            case ModelType.WASTE_DATAS:
                return WasteData;
            case ModelType.EXPENSES:
                return Expense;
            case ModelType.VAT_RATES:
                return VatRate;
            case ModelType.INVOICES:
                return Invoice;
            case ModelType.INVOICE_AUTOSAVES:
                return InvoiceAutosave;
            case ModelType.INVOICE_SECTIONS:
                return InvoiceSection;
            case ModelType.INVOICE_LINES:
                return InvoiceLine;
            case ModelType.MODEL_EVENTS:
                return ModelEvent;
            case ModelType.INVOICES_ANNEX_PDFS:
                return InvoiceAnnexPdf;
            case ModelType.INVOICE_PAYMENTS:
                return InvoicePayment;
            case ModelType.INVOICE_DISCOUNTS:
                return InvoiceDiscount;
            case ModelType.INVOICING_NUMBERING_SETTINGS:
                return InvoicingNumberingSetting;
            case ModelType.INVOICING_DOCUMENTS_SETTINGS:
                return InvoicingDocumentsSetting;
            case ModelType.INVOICING_NOTIFICATIONS_SETTINGS:
                return InvoicingNotificationsSetting;
            case ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS:
                return InvoicingPaymentRemindersSetting;

            case ModelType.PLANNING_ITEMS:
                return PlanningItem;
            case ModelType.TIME_ACTIVITIES:
                return TimeActivity;
            case ModelType.TIME_ACTIVITY_PAUSES:
                return TimeActivityPause;
            case ModelType.TIME_ACTIVITY_GEOLOCATIONS:
                return TimeActivityGeolocation;
            case ModelType.CALENDAR_EVENTS:
                return CalendarEvent;
            case ModelType.TIME_ACTIVITY_SETTINGS:
                return TimeActivitySetting;

            case ModelType.WORKSITES:
                return Worksite;
            case ModelType.MODEL_FILES:
                return ModelFile;
            case ModelType.MODEL_FILE_FOLDERS:
                return ModelFileFolder;
            case ModelType.WORKSITE_PHASES:
                return WorksitePhase;
            case ModelType.WORKSITE_PROFITABILITY_DOCUMENTS:
                return WorksiteProfitabilityDocument;
            case ModelType.WORKSITE_PROGRESS_BILLINGS:
                return WorksiteProgressBilling;
            case ModelType.WORKSITE_PROGRESS_BILLING_LINES:
                return WorksiteProgressBillingLine;
            case ModelType.WORKSITES_CLOSURE_REPORT:
                return WorksiteClosureReport;

            case ModelType.PDFS:
                return Pdf;
            case ModelType.PDF_IMAGES:
                return PdfImage;

            case ModelType.NOTIFICATIONS_LOGS:
                return NotificationLog;
            case ModelType.WORKFIELDS:
                return Workfield;
            case ModelType.WASTE_CENTER:
                return WasteCenter;
            case ModelType.IMPORTED_FILE:
                return ImportedFile;
            case ModelType.BANK_ACCOUNTS:
                return BankAccount;
            case ModelType.BANK_ACCOUNT_TRANSACTIONS:
                return BankAccountTransaction;
            case ModelType.BANK_ACCOUNT_AUTHORIZATIONS:
                return BankAccountAuthorization;
            case ModelType.INVOICE_TEMPLATES:
                return InvoiceTemplate;
            case ModelType.INVOICE_TEMPLATE_SECTIONS:
                return InvoiceTemplateSection;
            case ModelType.INVOICE_TEMPLATE_SECTION_COLUMNS:
                return InvoiceTemplateSectionColumn;
            case ModelType.GLOBAL_SEARCH_ITEMS:
                return GlobalSearchItem;
            case ModelType.SETTINGS:
                return Setting;
            case ModelType.CONNECTIONS:
                return Connection;
            case ModelType.PRODUCT_IMAGES:
                return ProductImage;
            case ModelType.NOTIFICATIONS:
                return Notification;
            case ModelType.TERMS_OF_SERVICES:
                return TermsOfService;
            case ModelType.TERMS_OF_SERVICE_ACCEPTATIONS:
                return TermsOfServiceAcceptation;
            // {{SCAFFOLDING}}
        }

        throw new Error(`ModelFactory: No model found for type '${type}'.`);
    }

    static getClassByPrefix(prefix: string): any {
        switch (prefix) {
            case Task.prefix:
                return Task;
            case CalendarEvent.prefix:
                return CalendarEvent;
            case TimeActivity.prefix:
                return TimeActivity;
            case Worksite.prefix:
            case 'worksites':
                return Worksite;
        }
    }

    static make(type: ModelType, schema: any, included: Array<typeof BaseSchema> = []) {
        const modelClass = this.getClass(type);
        return new modelClass(schema, included);
    }
}
