import LoginQrCodeEndpoint from '../auth/endpoints/LoginQrCodeEndpoint';
import TenantEndpoint from '../auth/endpoints/TenantEndpoint';
import UserEndpoint from '../auth/endpoints/UserEndpoint';
import { ModelType } from '../base/schemas/BaseSchema';
import NotificationLogEndpoint from '../common/endpoints/NotificationLogEndpoint';
import WorkfieldEndpoint from '../common/endpoints/WorkfieldEndpoint';
import ContactEndpoint from '../contact/endpoints/ContactEndpoint';
import LeadEndpoint from '../crm/endpoints/LeadEndpoint';
import ProductCategoryEndpoint from '../inventory/endpoints/ProductCategoryEndpoint';
import ProductEndpoint from '../inventory/endpoints/ProductEndpoint';
import ProductSectionEndpoint from '../inventory/endpoints/ProductSectionEndpoint';
import ProductUnitEndpoint from '../inventory/endpoints/ProductUnitEndpoint';
import ExpenseEndpoint from '../invoicing/endpoints/ExpenseEndpoint';
import InvoiceAutosaveEndpoint from '../invoicing/endpoints/InvoiceAutosaveEndpoint';
import InvoiceEndpoint from '../invoicing/endpoints/InvoiceEndpoint';
import InvoicePaymentEndpoint from '../invoicing/endpoints/InvoicePaymentEndpoint';
import InvoicingDocumentsSettingEndpoint from '../invoicing/endpoints/InvoicingDocumentsSettingEndpoint';
import InvoicingNotificationsSettingEndpoint from '../invoicing/endpoints/InvoicingNotificationsSettingEndpoint';
import InvoicingNumberingSettingEndpoint from '../invoicing/endpoints/InvoicingNumberingSettingEndpoint';
import InvoicingPaymentRemindersSettingEndpoint from '../invoicing/endpoints/InvoicingPaymentRemindersSettingEndpoint';
import VatRateEndpoint from '../invoicing/endpoints/VatRateEndpoint';
import PdfEndpoint from '../pdf/endpoints/PdfEndpoint';
import CalendarEventEndpoint from '../planning/endpoints/CalendarEventEndpoint';
import TimeActivityEndpoint from '../planning/endpoints/TimeActivityEndpoint';
import TaskEndpoint from '../task/endpoints/TaskEndpoint';
import ModelFileEndpoint from '../common/endpoints/ModelFileEndpoint';
import ModelFileFolderEndpoint from '../common/endpoints/ModelFileFolderEndpoint';
import WorksitePhaseEndpoint from '../worksite/endpoints/WorksitePhaseEndpoint';
import WorksiteProfitabilityDocumentEndpoint from '../worksite/endpoints/WorksiteProfitabilityDocumentEndpoint';
import WorksiteEndpoint from '../worksite/endpoints/WorskiteEndpoint';
import WorksiteProgressBillingEndpoint from '../worksite/endpoints/WorskiteProgressBillingEndpoint';
import WorksiteProgressBillingLineEndpoint from '../worksite/endpoints/WorskiteProgressBillingLineEndpoint';
import SourceEndpoint from '../crm/endpoints/SourceEndpoint';
import ImportedFileEndpoint from '../common/endpoints/ImportedFileEndpoint';
import LoginMagicLinkEndpoint from '../auth/endpoints/LoginMagicLinkEndpoint';
import ConnectionsEndpoint from '../common/endpoints/ConnectionsEndpoint';
import WasteDataEndpoint from '../invoicing/endpoints/WasteDataEndpoint';
import BankAccountEndpoint from '~/app/bankaccount/endpoints/BankAccountEndpoint';
import BankAccountTransactionEndpoint from '~/app/bankaccount/endpoints/BankAccountTransactionEndpoint';
import InvoiceTemplateEndpoint from '~/app/invoicing/endpoints/InvoiceTemplateEndpoint';
import InvoiceTemplateSectionEndpoint from '~/app/invoicing/endpoints/InvoiceTemplateSectionEndpoint';
import InvoiceTemplateSectionColumnEndpoint from '~/app/invoicing/endpoints/InvoiceTemplateSectionColumnEndpoint';
import InvoiceAnnexPdfEndpoint from '~/app/invoicing/endpoints/InvoiceAnnexPdfEndpoint';
import PlanningItemEndpoint from '~/app/planning/endpoints/PlanningItemEndpoint';
import GlobalSearchItemEndpoint from '@/app/common/endpoints/GlobalSearchItemEndpoint';
import SettingEndpoint from '@/app/common/endpoints/SettingEndpoint';
import ProductImageEndpoint from '@/app/inventory/endpoints/ProductImageEndpoint';
import NotificationEndpoint from '@/app/common/endpoints/NotificationEndpoint';
import TimeActivitySettingEndpoint from '@/app/planning/endpoints/TimeActivitySettingEndpoint';
import InvoiceDiscountEndpoint from '@/app/invoicing/endpoints/InvoiceDiscountEndpoint';
import WorskiteClosureReportEndpoint from '@/app/worksite/endpoints/WorskiteClosureReportEndpoint';
import WasteCenterEndpoint from '@/app/common/endpoints/WasteCenterEndpoint';
import TermsOfServiceEndpoint from '@/app/common/endpoints/TermsOfServiceEndpoint';
import TermsOfServiceAcceptationEndpoint from '@/app/common/endpoints/TermsOfServiceAcceptationEndpoint';
import ComposedWorkLineEndpoint from '@/app/inventory/endpoints/ComposedWorkLineEndpoint';
// {{SCAFFOLDING_IMPORT}}

export const ENDPOINT_CONSTRUCTOR = {
    [ModelType.BANK_ACCOUNTS]: BankAccountEndpoint,
    [ModelType.BANK_ACCOUNT_TRANSACTIONS]: BankAccountTransactionEndpoint,
    [ModelType.USERS]: UserEndpoint,
    [ModelType.TENANTS]: TenantEndpoint,
    [ModelType.LOGIN_QR_CODES]: LoginQrCodeEndpoint,
    [ModelType.LOGIN_MAGIC_LINK]: LoginMagicLinkEndpoint,
    [ModelType.CONTACTS]: ContactEndpoint,

    [ModelType.LEADS]: LeadEndpoint,
    [ModelType.SOURCES]: SourceEndpoint,
    [ModelType.TASKS]: TaskEndpoint,
    [ModelType.PRODUCTS]: ProductEndpoint,
    [ModelType.PRODUCT_UNITS]: ProductUnitEndpoint,
    [ModelType.PRODUCT_CATEGORIES]: ProductCategoryEndpoint,
    [ModelType.PRODUCT_SECTIONS]: ProductSectionEndpoint,
    [ModelType.WASTE_DATAS]: WasteDataEndpoint,
    [ModelType.EXPENSES]: ExpenseEndpoint,
    [ModelType.VAT_RATES]: VatRateEndpoint,
    [ModelType.INVOICES]: InvoiceEndpoint,
    [ModelType.INVOICE_AUTOSAVES]: InvoiceAutosaveEndpoint,
    [ModelType.INVOICE_PAYMENTS]: InvoicePaymentEndpoint,
    [ModelType.INVOICE_DISCOUNTS]: InvoiceDiscountEndpoint,
    [ModelType.INVOICING_NUMBERING_SETTINGS]: InvoicingNumberingSettingEndpoint,
    [ModelType.INVOICING_DOCUMENTS_SETTINGS]: InvoicingDocumentsSettingEndpoint,
    [ModelType.INVOICING_NOTIFICATIONS_SETTINGS]: InvoicingNotificationsSettingEndpoint,
    [ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS]: InvoicingPaymentRemindersSettingEndpoint,
    [ModelType.TIME_ACTIVITIES]: TimeActivityEndpoint,
    [ModelType.CALENDAR_EVENTS]: CalendarEventEndpoint,
    [ModelType.TIME_ACTIVITY_SETTINGS]: TimeActivitySettingEndpoint,
    [ModelType.WORKSITES]: WorksiteEndpoint,
    [ModelType.WASTE_CENTER]: WasteCenterEndpoint,
    [ModelType.MODEL_FILES]: ModelFileEndpoint,
    [ModelType.MODEL_FILE_FOLDERS]: ModelFileFolderEndpoint,
    [ModelType.WORKSITE_PHASES]: WorksitePhaseEndpoint,
    [ModelType.WORKSITE_PROFITABILITY_DOCUMENTS]: WorksiteProfitabilityDocumentEndpoint,
    [ModelType.WORKSITE_PROGRESS_BILLINGS]: WorksiteProgressBillingEndpoint,
    [ModelType.WORKSITE_PROGRESS_BILLING_LINES]: WorksiteProgressBillingLineEndpoint,
    [ModelType.WORKSITES_CLOSURE_REPORT]: WorskiteClosureReportEndpoint,

    [ModelType.PDFS]: PdfEndpoint,
    [ModelType.NOTIFICATIONS_LOGS]: NotificationLogEndpoint,
    [ModelType.WORKFIELDS]: WorkfieldEndpoint,
    [ModelType.IMPORTED_FILE]: ImportedFileEndpoint,
    [ModelType.INVOICES_ANNEX_PDFS]: InvoiceAnnexPdfEndpoint,
    [ModelType.INVOICE_TEMPLATES]: InvoiceTemplateEndpoint,
    [ModelType.INVOICE_TEMPLATE_SECTIONS]: InvoiceTemplateSectionEndpoint,
    [ModelType.INVOICE_TEMPLATE_SECTION_COLUMNS]: InvoiceTemplateSectionColumnEndpoint,
    [ModelType.PLANNING_ITEMS]: PlanningItemEndpoint,
    [ModelType.GLOBAL_SEARCH_ITEMS]: GlobalSearchItemEndpoint,
    [ModelType.SETTINGS]: SettingEndpoint,
    [ModelType.CONNECTIONS]: ConnectionsEndpoint,

    [ModelType.PRODUCT_IMAGES]: ProductImageEndpoint,
    [ModelType.NOTIFICATIONS]: NotificationEndpoint,
    [ModelType.TERMS_OF_SERVICES]: TermsOfServiceEndpoint,
    [ModelType.TERMS_OF_SERVICE_ACCEPTATIONS]: TermsOfServiceAcceptationEndpoint,
    [ModelType.COMPOSED_WORK_LINES]: ComposedWorkLineEndpoint,
    // {{SCAFFOLDING}}
} as const;

export default class EndpointFactory {
    static worksite(): WorksiteEndpoint {
        return new WorksiteEndpoint();
    }

    static make<TModelType extends keyof typeof ENDPOINT_CONSTRUCTOR>(type: TModelType): InstanceType<(typeof ENDPOINT_CONSTRUCTOR)[TModelType]> {
        return new ENDPOINT_CONSTRUCTOR[type]();
    }
}
