import { TenantAuthedMeta } from '../schemas/TenantSchema';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class User extends BaseModel {
    type: ModelType = ModelType.USERS;
    static type: ModelType = ModelType.USERS;
    prefix: string = 'trustup:user';
    static prefix: string = 'trustup:user';

    get firstName(): string {
        return this.attributes?.firstName;
    }

    get lastName(): string {
        return this.attributes?.lastName;
    }

    get email(): string {
        return this.attributes?.email;
    }

    get phone(): string {
        return this.attributes?.phone;
    }

    get avatar(): string {
        return this.attributes?.avatar;
    }

    get locale(): string {
        return this.attributes?.locale;
    }

    get language(): string {
        return this.locale.split('-')[1];
    }

    get priceLocale(): string {
        const [country, language] = this.locale.split('-');
        return `${language}-${country}`;
    }

    /**
     * TODO: Maybe we can accept multiple currencies in the future.
     */
    get priceCurrency(): string {
        return 'EUR';
    }

    get name(): string {
        return `${this.attributes?.firstName ?? ''} ${this.attributes?.lastName ?? ''}`.trim();
    }

    get fullName(): string {
        return this.name;
    }

    get displayName(): string {
        return `${this.attributes?.firstName} ${this.attributes?.lastName.charAt(0)}.`;
    }

    get isActive(): boolean {
        return this.attributes?.isActive;
    }

    get isInactive(): boolean {
        return !this.isActive;
    }

    get intercomHash(): string {
        return this.attributes?.intercomHashTrustupPro;
    }

    get role(): string {
        return this.attributes?.role;
    }

    get permissions(): Record<string, boolean> {
        return this.attributes?.permissions;
    }

    hasPermission(permission: string, defaultValue: boolean = false): boolean {
        return this.permissions ? this.permissions[permission] : defaultValue;
    }

    get options(): Record<string, any> {
        return this.attributes?.options;
    }

    get color(): number | null {
        return this.options?.color ?? null;
    }

    get hourlyCost(): number | null {
        return this.options?.hourlyCost ?? null;
    }

    get hourlyRate(): number | null {
        return this.options?.hourlyRate ?? null;
    }

    get weeklyHours(): number | null {
        return this.options?.weeklyHours ?? null;
    }

    get timeActivityDefaultTitle(): string | null {
        return this.options?.timeActivityDefaultTitle ?? null;
    }

    setTenantData({ role, permissions, options, isActive }: TenantAuthedMeta) {
        this.attributes.role = role;
        this.attributes.permissions = permissions;
        this.attributes.options = options;
        this.attributes.isActive = isActive;
    }

    resetTenantData() {
        this.attributes.role = null;
        this.attributes.permissions = null;
        this.attributes.options = null;
        this.attributes.isActive = null;
    }
}
