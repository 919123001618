import ExpenseCollection from '../collections/ExpenseCollection';
import WasteData from '../models/WasteData';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class WasteDataEndpoint extends BaseEndpoint<WasteData, ExpenseCollection> {
    model: ModelType = ModelType.WASTE_DATAS;
    path = 'tenant/invoicing/waste-datas';
    include = 'wasteCenter';
}
