import Str from '@supercharge/strings';
import User from '~~/app/auth/models/User';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

interface ModelEventData {
    channel?: string;
    modelId?: string;
    amount?: number;
    previousAmount?: number;
    replicatedId?: string;
    replicatedDocumentType?: string;
    depositData?: object;
    level?: number;
    views?: number;
    description?: string;
    details?: string;
}

export default class ModelEvent extends BaseModel {
    type: ModelType = ModelType.MODEL_EVENTS;
    static type: ModelType = ModelType.MODEL_EVENTS;
    prefix: string = 'common:model-event';
    static prefix: string = 'common:model-event';

    get user(): User | null {
        return this.relationships?.user?.data || null;
    }

    get model() {
        return this.relationships?.model?.data || null;
    }

    get responsibleModel() {
        return this.relationships?.responsibleModel?.data || null;
    }

    get event(): string | null {
        return this.attributes.event;
    }

    get data(): ModelEventData | null {
        return this.attributes.data;
    }

    get name(): string {
        const { t } = useTranslation();
        const eventName = Str(this.event).snake();

        if (this.event === 'replicated' && this.data?.replicatedDocumentType) {
            return t(`model_events.duplicated_as_${Str(this.data?.replicatedDocumentType).snake()}`);
        }

        if (this.event === 'payment_reminder') {
            const level = this.data?.level;

            return level ? t(`model_events.payment_reminder_${level}`) : t(`model_events.payment_reminder`);
        }

        if (this.event === 'deposit_invoice_created' && this.data?.depositData) {
            return t(`model_events.deposit_invoice_created_with_percentage`, { percentage: this.data?.depositData.percentage });
        }

        if (this.event === 'notification') {
            if (this.data?.channel === 'email') {
                return t(`model_events.email_sent`);
            }
            if (this.data?.channel === 'sms') {
                return t(`model_events.sms_sent`);
            }
            if (this.data?.channel === 'letter') {
                return t(`model_events.letter_sent`);
            }
        }

        if (Str(this.event).startsWith('time_activity')) {
            const name = this.data?.user_name ?? 'Unknown';
            return t(`model_events.${eventName}`, { name });
        }
        // TODO
        if (this.event === 'model_file_uploaded') {
            return t(`model_events.${eventName}`, 1);
        }

        return t(`model_events.${eventName}`);
    }

    get subTitle(): string | null {
        const { t } = useTranslation();

        if (this.event === 'email_viewed' && this.data?.views) {
            return t(`model_events.email_viewed_subtitle`, { views: this.data?.views });
        }

        if ((this.event === 'email_failed' || this.event === 'sms_failed') && (this.data?.description || this.data?.details)) {
            return this.data?.description || this.data?.details;
        }

        return null;
    }

    get historyColors(): object {
        return {
            background: {
                blue: '#E9F4FF',
                red: '#FFE9E9',
                orange: '#FFF1E4',
                gray: '#F1F1F1',
                green: '#E8F8EE',
            },
            icon: {
                blue: '#002ED8',
                red: '#dc2626',
                orange: '#FC8F2B',
                gray: '#686868',
                green: '#059669',
            },
        };
    }

    get historyIcon(): string {
        switch (this.event) {
            case 'task_message_created':
            case 'time_activity_message_created':
                return 'fa-comment';

            case 'time_activity_started':
            case 'time_activity_paused':
            case 'time_activity_restarted':
            case 'time_activity_stopped':
                return 'fa-timer';

            case 'task_created':
            case 'task_updated':
            case 'task_deleted':
                return 'fa-check';

            case 'task_completed':
            case 'sent':
            case 'paid':
                return 'fa-circle-check';

            case 'task_uncompleted':
            case 'overdue':
            case 'payment_deleted':
                return 'fa-circle-xmark';

            case 'model_file_uploaded':
                return 'fa-cloud-arrow-up';

            case 'notification':
                return 'fa-paper-plane';

            case 'payment_created':
            case 'payment_updated':
            case 'payment_reminder':
                return 'fa-euro';

            case 'duplicated':
            case 'replicated':
                return 'fa-copy';

            default:
                return 'fa-info-circle';
        }
    }

    get historyColor(): string {
        if (this.event === 'notification' || this.event === 'sent' || this.event === 'task_message_created' || this.event === 'time_actvity_message_created') {
            return 'blue';
        }

        if (
            this.event === 'payment_reminder' ||
            this.event === 'payment_deleted' ||
            this.event === 'overdue' ||
            this.event === 'declined_by_client' ||
            (this.event === 'status' && this.data?.new === 'rejected') ||
            this.event === 'email_failed' ||
            this.event === 'sms_failed' ||
            this.event === 'task_uncompleted'
        ) {
            return 'red';
        }

        if (
            this.event === 'paid' ||
            this.event === 'payment_created' ||
            this.event === 'accepted_by_client' ||
            (this.event === 'status' && this.data?.new === 'accepted') ||
            this.event === 'email_viewed' ||
            this.event === 'task_completed' ||
            this.event === 'time_activity_stopped'
        ) {
            return 'green';
        }

        if (this.event === 'time_activity_paused' || this.event === 'time_activity_restarted') {
            return 'orange';
        }

        return 'gray';
    }

    get historyIconColor(): string {
        return this.historyColors.icon[this.historyColor];
    }

    get historyBubbleColor(): string {
        return this.historyColors.background[this.historyColor];
    }
}
