import WasteCenter from '~/app/common/models/WasteCenter';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class WasteData extends BaseModel {
    type: ModelType = ModelType.WASTE_DATAS;
    static type: ModelType = ModelType.WASTE_DATAS;
    prefix = 'invoicing:waste-data';
    static prefix = 'invoicing:waste-data';

    get relationshipsMap(): Record<string, ModelType> {
        return {
            invoice: ModelType.INVOICES,
            wasteCenter: ModelType.WASTE_CENTER,
        };
    }

    get wasteCenter(): WasteCenter | null {
        return this.relationships?.wasteCenter?.data || null;
    }

    get wasteType(): string {
        return this.attributes.wasteType;
    }

    get cost(): number {
        return this.attributes.cost;
    }

    get estimatedVolumeHigh(): number {
        return this.attributes.estimatedVolumeHigh;
    }

    get estimatedVolumeLow(): number {
        return this.attributes.estimatedVolumeLow;
    }
}
